import React, {useEffect, useState} from 'react';
import Menu from "./Menu";
import "./Navigator.less"
import "./Menu.less"
import AJPopover, {AJPopover2} from "../ui/AJPopover";
import {useHistory} from 'react-router'
import SvgFavoriteIcon from "../icons/SvgFavoriteIcon";
import SvgMenuIcon from "../icons/SvgMenuIcon";
import {Badge, Row, Col, Icon, Dropdown, Menu as AntdMenu} from "antd";
import {getFavorite} from "../http/AJHttp";
import {isLogged} from "../storage/AJStorage";
import AJModalV2 from "../ui/AJModalV2";
import AJCard from "../ui/AJCard";
import EmptyLine from "../ui/EmptyLine";
import EmptySpan from "../ui/EmptySpan";
import AJButton3 from "../ui/AJButton3";
import VCenterContainer from "../ui/VCenterContainer";
import AJLogo from "../ui/AJLogo";
import {HowItWorksContent} from "./HowItWorks";
import {gotoLink} from "./start/StartFooter";

function Navigator(props) {
    let {colorStyle, showFavorite, onClickFavorite, onClickMenu} = props
    showFavorite = showFavorite || !isLogged()
    colorStyle = colorStyle || 'white'
    onClickFavorite = onClickFavorite || (() => {})
    onClickMenu = onClickMenu || (() => {})

    const [menuVisible, setMenuVisible] = useState(false)
    const [toggleClass, setToggleClass] = useState("navigator-menu-detail")
    const [showHowWorks, setShowHowWorks] = useState(false)
    const [expandHowItWorks, setExpandHowItWorks] = useState(false)
    const [showTalentWorks, setShowTalentWorks] = useState(false)
    // const [favorite, setFavorite] = useState([])
    const favorite = (Array.isArray(props.favorite) ? props.favorite : []) || []
    let history = useHistory();

    onClickFavorite = (() => {
        history.push('/company-saved')
    })

    const HowItWorks = () => (
        <AJModalV2
            visible={showHowWorks}
            closable={false}
            centered={true}
            width={570}
            onClose={()=>{
                setShowHowWorks(false)

            }}>
            <HowItWorksContent
                talent={showTalentWorks}
                onClose={() => setShowHowWorks(false)}/>
        </AJModalV2>
    )

    const background = colorStyle === 'white' ?  '#000' : '#FFF';
    const customStyle = props.customStyle || {};

    return (
        <div id="navigator">
            <div className={'navigator-container'} style={{background, ...customStyle}} >
                <div style={{ height: 32, position: 'absolute', top: 18, left: 50, right: 110}}>

                    <div className={'navigator-title'} style={{color : colorStyle, width: 'fit-content'}} onClick={()=>{
                        const currentPath = history.location.pathname
                        if (currentPath !== "/") {
                            history.push("/")
                        }
                    }}>
                        {colorStyle === 'white' ? <AJLogo/> : <AJLogo schema={'dark'}/>}
                    </div>

                    {showFavorite ?
                        <div onClick={onClickFavorite}>
                            <AJPopover
                                content={'Visit your favorite companies!'} width={147}>
                                <div className={'navigator-favorite'}>
                                    <Badge count={favorite.length}>
                                        <Icon type="heart" style={{fontSize: 24, color: colorStyle}}/>
                                    </Badge>
                                </div>
                            </AJPopover>
                        </div> : null}

                    <div className={'navigator-menu'} onClick={onClickMenu} >
                        <SvgMenuIcon width={24} height={20} fill={colorStyle}/>
                    </div>

                    <div className={'how-it-works-title'} style={{color: colorStyle, width: "auto"}}>
                        <div style={{display: "flex", justifyContent: "space-around"}}>
                            <div onClick={() => {
                                addGA('True Fit', '/toolbox');
                                gotoLink('/toolbox', history)
                            }}>
                                True Fit
                            </div>
                            <EmptySpan width={50}/>
                            <div onClick={() => {
                                addGA('News & Media', '/latest');
                                gotoLink('/latest', history)
                            }}>
                                News & Media
                            </div>
                            <EmptySpan width={50}/>
                            <div onClick={() => {
                                addGA('Talent', '/talent');
                                gotoLink('/talent', history)
                            }}>
                                Talent
                            </div>
                            <EmptySpan width={50}/>
                            <div onClick={() => {
                                addGA('Employer', '/employer');
                                gotoLink('/employer', history)
                            }}>
                                Employer
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function toggleMenu() {
        setMenuVisible(!menuVisible)
        if (!menuVisible) {
            setToggleClass('navigator-menu-detail-show')
        }
        else {
            setToggleClass('')
        }
    }
}

function addGA(eventAction, pageUrl) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'custom_event',
        'eventCategory': 'navigation_menu',
        'eventAction': `${eventAction}`,
        'eventlabel': `${pageUrl}`
    });
}

export default Navigator;
