import React, {useState} from "react";
import "./CompanyProfileTopValues.less";
import EmptyLine from "../../ui/EmptyLine";
import {EditIcon} from "../../page/CompanyProfile2";
import {getOrderedValues} from "./CompanyProfileValues";
import {Col} from "antd";
import AJButton3 from "../../ui/AJButton3";
import ValueMediaUpload from "../ValueMediaUpload";
import EmptySpan from "../../ui/EmptySpan";
import {AJEditableDiv2} from "../../ui/AJRichEditor";
import {defaultValuesConfig, valuePatch} from "../values/ValuesConfig";
import {AJClickPopover} from "../../ui/AJPopover";

export default function CompanyProfileTopValues(props) {
    const {profileData, version} = props
    const topValues = profileData.describeValues

    const values = getOrderedValues(profileData)
    const orderedTopValues = []
    values.forEach(v => {
        for (const key in topValues) {
            if (topValues[key].value === v) {
                orderedTopValues.push(topValues[key])
                break
            }
        }
    })
    return (
        <div>
            <EmptyLine height={9}/>
            {
                orderedTopValues.map((topValue, index) =>
                    <ValueDescription {...props} hiddenVideo={orderedTopValues.length === index+1} value={topValue} key={`${index}-${version}`}/>
                )
            }
        </div>
    )
}

function ValueDescription(props) {
    const {editing, value, version, hiddenVideo} = props

    function searchValueConfig(value) {
        for (const valueConfig of defaultValuesConfig) {
            for (const v of valueConfig.values) {
                if (value === valuePatch(v.value)) {
                    console.log(v)
                    return v
                }
            }
        }
        return {}
    }
    return (
        <div className={'company-profile2-value-desc'}>
            <div className={'company-profile2-value-desc-title'}>
                <div style={{display: 'flex'}}>
                    {editing? <EditIcon/> : null}
                    <div style={{flex:1}}>{valuePatch(value.value)}</div>
                    {editing? <Col>
                        <QuestionsClickPopOver questions={searchValueConfig(valuePatch(value.value)).questions} />
                    </Col> : null}
                </div>
            </div>
            <EmptyLine height={12}/>

            <div className={'company-profile2-value-desc-text'}>
                <AJEditableDiv2 readonly={!editing}
                                version={version}
                                defaultValue={value.text}
                                onChange={(newValue) => {
                                    value.text = newValue
                                }}
                                width={570}/>
            </div>
            <EmptyLine height={27}/>

            <div>
                <ValueMediaUpload
                    readonly={!editing}
                    hiddenVideo={hiddenVideo}
                    value={value}
                    height={375}
                    width={570}
                    onVideoChange={()=>{}}
                    onVideoSave={(video) => {
                        value.video = video
                        value.image = null
                    }}
                    onImageChange={(image) => {
                        value.video = null
                        value.image = image
                    }}
                    footer={
                        ({openUploadingImage, showLinkVideoModel}) =>
                            <>
                                <div style={{width: 578, textAlign: 'right', paddingRight: 20}}>
                                    <AJButton3
                                        title={'Upload Image'}
                                        titleStyle={{fontSize: '10px'}}
                                        size={'mini'}
                                        width={127}
                                        type={'secondary'}
                                        height={30}
                                        onClick={() => {
                                            openUploadingImage()
                                        }}/>
                                    <EmptySpan width={12}/>
                                    <AJButton3 title={'Link Video'}
                                        titleStyle={{fontSize: '10px'}}
                                        size={'mini'}
                                        width={127}
                                        type={'secondary'}
                                        height={30}
                                        onClick={()=>{
                                            showLinkVideoModel(true)
                                        }}/>
                                </div>
                            </>
                    }/>
            </div>
        </div>
    )
}

export function SupportQuestions(props) {
    const questions = props.questions || <>No Supported Questions</>

    return (
        <div className={'support-questions-container'}>
            <div className={'support-questions-close'}>
                <img src={'/img/login/close-black.svg'} width={'100%'} height={'100%'} onClick={props.onClose}/>
            </div>
            <div className={'support-questions-title'}>
                Support questions
            </div>
            <br/>
            <div className={'support-questions-body'}>
                {questions}
            </div>
        </div>
    )
}

export function QuestionsClickPopOver({questions, scheme}) {
    const [popupVisible, setPopupVisible] = useState(false);
    const popScheme = scheme || 'deep-yellow';

    return (
        <AJClickPopover
            content={<SupportQuestions
                questions={questions}
                onClose={() => setPopupVisible(false)}
            />}
            scheme={popScheme}
            placement={'right'}
            visible={popupVisible}
            onVisibleChange={(visible) => {
                setPopupVisible(visible)
            }}>
            <div style={{
                cursor: 'pointer'
            }} onClick={() => setPopupVisible(!popupVisible)}>
                <span style={{fontFamily: 'ManropeSemiBold', fontSize:12, fontWeight: 600, color: 'rgb(255, 216, 101)'}}><u>Click here before writing</u></span>
                &nbsp;
                <svg style={{verticalAlign: 'middle'}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z" fill="rgb(255, 216, 101)"/>
                </svg>
            </div>
        </AJClickPopover>
    );
}
