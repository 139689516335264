import React from "react"
import Logo3 from "../../icons/Logo3";
import "./CEOReviewCard.less"
import EmptyLine from "../../ui/EmptyLine";


export default function CEOReviewCard({avatar, introduce, comment}) {
    return <div className={'ceo-review-card'}>
        <EmptyLine height={25}/>
        <div className={'ceo-review-card-context'}>

            <div style={{width: 100}}>
                <div className={'ceo-review-card-avatar'}>
                    <img src={avatar} alt={'ceo avatar'} width={'100%'} height={'100%'}/>
                </div>
                <EmptyLine height={8}/>
                <div>
                    {introduce}
                </div>
            </div>
            <div style={{width: 35}} />
            <div style={{width: 360}} className={'ceo-review-card-comment'}>
                {comment}
            </div>
        </div>
    </div>

}