import React from "react"
import Logo from "../icons/Logo";
import Logo2 from "../icons/Logo2";

export default function AJLogo(props) {

    let {width, height, schema} = props

    width = width || 168
    height = height || 32
    schema = schema || 'white'
    const logo = schema === 'white' ? <Logo2 height={height} width={width}/> : <Logo height={height} width={width}/>

    return <>{logo}</>

}
