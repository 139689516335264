import React, {useEffect, useMemo, useRef, useState} from "react";
import {useHistory} from 'react-router';
import CompanyProfileEditorProvider from "../context/CompanyProfileEditorProvider";
import CompanyProfileImageContainer from "../component/company/CompanyProfileImageContainer";
import {Button, Col, Row, Upload, message, Table, Icon, Modal} from "antd"
import CompanyProfileHeader3 from "../component/company/CompanyProfileHeader3";
import {
    companyProfile,
    getFavorite,
    jobExtract,
    sendCommunityMail,
    updateCompanyProfile
} from "../http/AJHttp";
import {
    currentCompanyId,
    currentIsAdmin,
    currentUserId,
    getLoginFTU,
    isLogged,
    setCurrentCompanyId, setJoinedCommunity,
    setLoginFTU
} from "../storage/AJStorage";
import EmptyLine from "../ui/EmptyLine";
import JobOpenings2 from "../component/company/JobOpenings2";
import {useParams} from "react-router-dom";
import AJInput2, {AJEmailInput, AJUrlInput} from "../ui/AJInput2";
import AJButton3, {AJCenterButton} from "../ui/AJButton3";
import "./CompanyProfile3.less";
import {arrayContains} from "../utils/arrayUtil";
import AJModalV2 from "../ui/AJModalV2";
import AJCard from "../ui/AJCard";
import { Radio, Input } from 'antd';
import {AJFullScreenLoading} from "../ui/AJLoading";
import StartFooter from "../component/start/StartFooter";
import ReactGA from 'react-ga';
import uuid from "react-uuid";
import companyConfig from "../component/company/CompanyConfig";
import DocumentTitle  from "../component/DocumentTitle";
import { Collapse } from 'antd';
import {getOrderedValues} from "../../src/component/company/CompanyProfileValues";
import {valuePatch} from "../../src/component/values/ValuesConfig";
import SvgValueIcon from "../icons/valueIcon";
import SvgRightNarrow from "../icons/rightNarrow";
import AJVideoPlayer from "../ui/AJVideoPlayer";
import {CompanyProfileTechStack2} from "../component/company/CompanyProfileTechStack";
import AJPopover from "../ui/AJPopover";

const { Dragger } = Upload;
// TODO
function validateProfileData(profileData) {
    const orderedValues = profileData.orderedValues ?? []
    const describeValues = profileData.describeValues ?? []
    const orderedValuesNew = []
    const values = []
    orderedValues.forEach(v =>{
        if (!arrayContains(values, v.text)) {
            values.push(v.text)
            orderedValuesNew.push(v)
        }
    })

    const orderedDescribeValues = {}
    values.forEach((v, index) => {
        let found = false
        for (const key in describeValues) {
            if (describeValues[key].value === v) {
                orderedDescribeValues[index + 1] = describeValues[key]
                found = true
                break
            }
        }
        if (!found) {
            orderedDescribeValues[index + 1] = {
                text: "",
                value: v
            }
        }

    })

    profileData.describeValues = orderedDescribeValues
    profileData.orderedValues = orderedValuesNew

    return profileData
}

async function getIp() {
    // const response =  await axios.get('https://api.ipify.org')
    // localStorage.setItem('local-ip', response.data)
}

export default function CompanyProfile3Container(props) {
    let { id } = useParams();
    const history = useHistory()
    const [editing, setEditing] = useState(false)
    const [profileData, setProfileData] = useState(null)
    const [savedProfileData, setSavedProfile] = useState(null)
    const [readOnly, setReadOnly] = useState(true)
    const [favoriteData, setFavoriteData] = useState(null)

    useEffect(() => {
        fetchProfileData();
        ReactGA.pageview(`/company-profile/`);
    }, [])

    const  fetchProfileData = async () => {
        const [response, response2, r3] = await Promise.all([companyProfile(id), getFavorite(), getIp()])
        if (response) {
            let rd = true

            if (currentIsAdmin()) {
                rd = false
            } else if (response.data.owner == currentUserId()) {
                rd = false
            }

            setSavedProfile(JSON.parse(JSON.stringify(response.data)))
            setProfileData(validateProfileData(response.data))
            setReadOnly(rd)
            if (response2) {
                setFavoriteData(response2.data)
            }
        }
        else {
            message.error("Get company profile failed!")
            history.push("/404")
        }
    }

    async function updateProfile(errorMessage, callback) {
        if (JSON.stringify(savedProfileData) !== JSON.stringify(profileData)) {
            const response = await updateCompanyProfile(id, profileData)
            if (response) {
                setProfileData(response.data)
                setSavedProfile(JSON.parse(JSON.stringify(response.data)))
                if (callback) {
                    callback()
                }
            }
            else {
                message.error(errorMessage || "Update company profile failed!")
            }
        }
    }

    document.getElementsByTagName('body')[0].style.background = props.background || 'transparent'
    return (
        <>
            {
                !profileData||!favoriteData ? <AJFullScreenLoading/>
                    :
                    <CompanyProfileEditorProvider>
                        <CompanyProfile3 refreshFavorite={props.refreshFavorite}
                                         favoriteData={favoriteData}
                                         readonly={readOnly}
                                         editing={editing}
                                         setEditing={setEditing}
                                         profileData={profileData}
                                         savedProfileData={savedProfileData}
                                         updateSavedProfileData={setSavedProfile}
                                         reloading={() => setProfileData(null)}
                                         published={profileData.published}
                                         updateProfile={updateProfile}/>
                    </CompanyProfileEditorProvider>
            }
        </>
    )
}

export function CompanyProfile3(props) {
    const {editing, profileData, readonly} = props
    const [showJobOpeningsEditing, setShowJobOpeningsEditing] = useState(false)
    const [coverImageLoaded, setCoverImageLoaded] = useState(false)
    const [showTooltips, setShowTooltips] = useState(false)
    useEffect(() => {
        if (isLogged() && !profileData.published
            && (currentUserId() === profileData.owner)) {
            const loginInfo = getLoginFTU()
            if (loginInfo && loginInfo.userId === currentUserId() && profileData.id === loginInfo.companyId) {
                return
            }
            else {
                if (!currentCompanyId()) {
                    setCurrentCompanyId(profileData.id)
                }
                setLoginFTU({
                    userId : currentUserId(),
                    companyId: profileData.id
                })
                setTimeout(() => setShowTooltips(true), 1000)
            }
        }
    }, [])

    const JoinCard = (props) => {
        const [sending, setSending] = useState(false)
        const [email, setEmail] = useState('')
        const [cardClass, setCardClass] = useState('join-community-card')
        const ref = useRef(null);

        let title = null
        let description = null
        let image = null
        if (profileData && profileData.published) {
            const companyName = profileData.name
            const config = companyConfig[companyName]  || {}
            title = config?.name || profileData.name
            description = config.description || profileData.introducation
            image = `${window.location.origin}${profileData.companyStructure?.imageUrl}`
        }

        return (
            <div ref={ref} className={'company-profile3-join-card'}>
                <DocumentTitle title={title} description={description} image={image} url={window.location.href}>
                    <div className={'join-community-card'} hidden={!props.readonly} id={'aj-join-community-card'}>
                        <div className={'join-community-card-container'}>
                            <div className={'join-community-card-title'}>
                                Stay Ahead
                            </div>
                            <EmptyLine height={14}/>
                            <div className={'join-community-card-desc'}>
                                Sign up for weekly <br/>newsletter
                            </div>
                            <EmptyLine height={42}/>
                            <AJEmailInput label={'Your e-mail here'}
                                          id={'aj-community-mail'}
                                          defaultValue={email} />

                            <EmptyLine height={59}/>
                            <span className={'join-community-card-privacy'} onClick={()=>{
                                window.open('/privacy')
                            }}>
                                Privacy
                            </span>
                            <AJButton3 title={'Send'}
                                size={'small'}
                                titleStyle={{color: 'black'}}
                                loading={sending}
                                height={43}
                                width={100}
                                background={'white'}
                                onClick={async ()=>{
                                    const email = document.getElementById('aj-community-mail').value
                                    setSending(true)

                                    const response = await sendCommunityMail(email)
                                    if (response && response.status===201) {
                                        message.info("Thank you for subscribing!")
                                        setCardClass('join-community-card-start-page')
                                        setJoinedCommunity()
                                    } else if (response && response.status===400 && response.data === 'this email have subcribed before') {
                                        message.error("Subscribe failed! This email has been subscribed before.")
                                    } else {
                                        message.error("Subscribe failed!")
                                    }
                                    setSending(false)
                                }}/>
                        </div>
                    </div>
                </DocumentTitle>
            </div>
        )
    }

    const GotoTop = (props) => {
        const ref = useRef(null);

        return (<div ref={ref} className={'company-profile3-goto-top'} onClick={(e) => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }}>
            <img src={'/img/company-profile/goto-top.svg'}/>
        </div>)
    }

    function ValueItem(props) {
        let [isHovered, setIsHovered] = useState(false)

        function handleClick() {
            const element = document.getElementById(props.value);
            if (element) {
                window.scrollTo({
                    top: element.getBoundingClientRect().top + window.scrollY-90,
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
                setTimeout(()=>{setActiveKey(activeKey => {
                    if(activeKey.includes(String(props.index+1))) {
                        shouldJobCardShow([String(props.index+1)]);
                        return [...activeKey];
                    } else {
                        shouldJobCardShow([...activeKey, String(props.index+1)]);
                        return [...activeKey, String(props.index+1)];
                    }
                })}, 800);
            }
        }

        return (
            <div key={props.key} className={'value-tag'}>
                <div className="value-content" onMouseEnter={()=>{setIsHovered(true)}} onMouseLeave={()=>{setIsHovered(false)}} onClick={()=>{handleClick()}}>
                    <SvgValueIcon fill={isHovered ? 'white' : 'black'}></SvgValueIcon>
                    <div style={{color: isHovered ? 'white' : 'black', marginTop: 7}}>{props.value}
                        &nbsp;
                        <SvgRightNarrow fill={isHovered ? 'white' : 'black'}></SvgRightNarrow>
                    </div>
                </div>
            </div>
        )
    }

    const { Panel } = Collapse;
    const orderedTopValues =useMemo(() => {
        const topValues = profileData.describeValues
        const values = getOrderedValues(profileData)
        const tmp = []
        values.forEach(v => {
            for (const key in topValues) {
                if (topValues[key].value === v) {
                    tmp.push(topValues[key])
                    break
                }
            }
        })
        return tmp;
    }, [profileData])
    const orderedValues = useMemo(() => getOrderedValues(props.profileData), [profileData]);
    let [activeKey, setActiveKey] = useState([])

    const [isPublishing, setIsPublishing] = useState(false)
    const [published, setPublished] = useState(props.profileData.published)
    const [alertVisible, setAlertVisible] = useState(false)
    let setEditing = props.setEditing || (()=>{})
    let saveEditing = props.updateProfile || (() => {})
    const [isSaving, setIsSaving] = useState(false)
    let [showJobCardKey, setShowJobCardKey] = useState(0)
    let history = useHistory()
    
    const renderAlert = () => {
        return (
            <div className={'company-profile-alert'}>
                <div className={'company-profile-alert-container'}>
                    <EmptyLine height={4}/>
                    <div className={'company-profile-alert-title'}>
                        Thank you!
                    </div>
                    <EmptyLine height={22}/>
                    <div className={'company-profile-alert-body'}>
                        We are now reviewing your amazing values.
                        <br/>
                        <br/>
                        Your Profile will be public within maximum 24 hours
                    </div>
                    <br/>
                    <EmptyLine height={20}/>
                    <AJCenterButton title={'OK'}
                                    width={274}
                                    height={60}
                                    titleStyle={{'color': 'white'}}
                                    onClick={()=>{
                                        setAlertVisible(false)
                                    }}/>
                </div>
            </div>
        )
    }

    const CanEditing = (
        <>
            <EmptyLine height={40}/>
            <div hidden={published} style={{display: 'inline'}}>
                <AJButton3 title={'Publish Profile'}
                           titleStyle={{'color': 'white'}}
                           width={273}
                           height={60}
                           shadow={true}
                           loading={isPublishing}
                           onClick={async () => {
                               setIsPublishing(true)
                               props.profileData.published = true
                               const response = await updateCompanyProfile(props.profileData.id, props.profileData)
                               setIsPublishing(false)
                               if (response) {
                                   setPublished(true)
                                   setAlertVisible(true)
                               }
                               else {
                                   message.error("Publish profile failed!")
                               }
                           }}/>
            </div>

            <AJPopover content={<>To make your profile<br/> attractive, click on edit<br/> profile.</>}
                       placement={'bottom'} width={160} scheme={'dark'} mouseEnterDelay={0.1}>
                <div style={{display: 'inline'}}>
                    <AJButton3 title={'Edit Profile'}
                            type={'secondary'}
                            scheme={'purple'}
                            width={273}
                            height={60}
                            shadow={true}
                            titleStyle={{color: '#ffdc74', fontSize: '21px'}}
                            onClick={() => {
                                const currentPath = history.location.pathname
                                if (currentPath.includes('company-profile')) {
                                    const newPath = currentPath.replace('company-profile', 'company-profile-edit')
                                    history.push({pathname: newPath, state: { skipEditButton: true }})
                                }
                            }}/>
                </div>
            </AJPopover>

            <AJModalV2 visible={alertVisible} width={338}
                       top={200} onClose={()=>setAlertVisible(false)}>
                {renderAlert()}
            </AJModalV2>
        </>
    )

    const expandIcon = ({ isActive }) =>
        isActive ? (
            <Icon
                type="minus"
                style={{fontSize: '30px'}}
            />
        ) : (
            <Icon
                type="plus"
                style={{fontSize: '30px'}}
        />
    );

    function shouldJobCardShow(keyList) {
        if (keyList.length === 0) {
            setShowJobCardKey(0);
        } else {
            setShowJobCardKey(Number(keyList[keyList.length - 1])-1);
        }
    }

    const nextEvent = profileData?.companyStructure?.misc?.nextEvent;

    return (
        <div>
            <div className="customer-page">
                {
                    readonly ? <></> : editing ?  <></> : 
                    <div className="edit-and-save-button-container" style={{'text-align': 'right'}}>
                        {
                            CanEditing
                        }
                    </div>
                }

                <div className="company-title-container">
                    <div className="company-name">{props.profileData.name}</div>
                    <div className="company-intro">{props.profileData.introducation}</div>
                </div>

                <CompanyProfileImageContainer {...props} onImageLoaded={()=>{
                    setCoverImageLoaded(true)
                }}/>

                <EmptyLine height={202}/>

                <CompanyProfileHeader3 {...props} logoUrl={'/img/common/img-place-holder.png'}/>

                <div className="jobs-title">Job Openings</div>
                <JobOpeningsEditing
                    key={Math.random()}
                    saveJobOpenings={
                        (newJobOpenings) => {
                            profileData.jobOpenings = newJobOpenings
                        }}
                    {...props}
                    show={showJobOpeningsEditing}
                    hide={()=>{
                        setShowJobOpeningsEditing(false)
                    }}/>
                <EmptyLine height={29}/>

                <JobOpenings2
                    editing={editing}
                    data={profileData.jobOpenings}
                    company={profileData.name}
                    isCustomer={profileData.owner === currentUserId()}/>

                <EmptyLine height={202}/>
                
                <div style={{color: 'black'}}>
                    <div className="values-title">Principles & values</div>
                    
                    <div className={'values-container'}>
                        {
                            orderedValues.map((v, index) => {
                                return (
                                    <ValueItem key={index} value={valuePatch(v)} index={index}></ValueItem>
                                );
                            })
                        }
                    </div>
                </div>

                <EmptyLine height={202}/>

                <Collapse bordered={false} activeKey={activeKey} expandIconPosition={'right'} expandIcon={(isActive)=>expandIcon(isActive)} onChange={key => {shouldJobCardShow(key); setActiveKey([...key])}}>
                    {
                        orderedTopValues.map((v, index) => {
                            return ( 
                                <Panel header={<p className="value-title" id={`${valuePatch(v.value)}`}>{valuePatch(v.value)}</p>} key={index+1} style={{background: 'white', overflow: 'hidden'}}>
                                    <div className="value-content-container">
                                        <div style={{flex: 1}}>
                                            <div style={{display: showJobCardKey === index ? '' : 'none'}}>
                                                {coverImageLoaded ? <JoinCard {...props} key={'ready'}/> : <JoinCard {...props} key={'not ready'}/>}
                                            </div>
                                        </div>
                                        <div className="value-content">
                                            <div className={'aj-rich-editor'} dangerouslySetInnerHTML={{__html: v.text}}/>
                                            {
                                                v.video ? 
                                                <div>
                                                    <AJVideoPlayer url={v.video} width={'100%'} height={'100%'}/>
                                                </div> : v.image ? <img src={`https://genvalues.com/${v.image}`} /> : <></>
                                            }
                                        </div>
                                    </div>
                                </Panel>
                            );
                        })
                    }
                </Collapse>

                <EmptyLine height={202}/>

                <div className="tech-container">
                    <div className="tech-content">
                        <div className="jobs-title">Our Technology</div>
                        <EmptyLine height={14}/>
                        <CompanyProfileTechStack2 {...props}/>
                    </div>
                    <div className="tech-pic">
                        <img src={"/img/company-profile/technology-pic.svg"} />
                    </div>
                </div>

                <EmptyLine height={202}/>

                {
                    nextEvent?.text ? 
                        <>
                            <div className="event-container">
                                <div className="tech-content">
                                    <div className="jobs-title">Join us at next event</div>    
                                </div>
                                <div className="tech-pic">
                                    {
                                        nextEvent?.video ? 
                                        <div>
                                            <AJVideoPlayer url={nextEvent?.video} width={'100%'} height={'100%'}/>
                                        </div> : nextEvent?.image ? <img src={`https://genvalues.com/${nextEvent?.image}`} /> : <img src={"/img/company-profile/event-pic.svg"} />
                                    }
                                    <EmptyLine height={40}/>
                                    <div className={'aj-rich-editor'} dangerouslySetInnerHTML={{__html: nextEvent?.text}}/>
                                </div>
                            </div>

                            <EmptyLine height={202}/>
                        </>
                         : <></>
                }
                
                {/* {coverImageLoaded ? <JoinCard {...props} key={'ready'}/> : <JoinCard {...props} key={'not ready'}/>} */}
                
                <GotoTop/>
            </div>

            <StartFooter/>
        </div>
    )
}

const JobOpeningsEditing = (props) => {
    const {saveJobOpenings} = props
    const {jobOpenings} = props.profileData
    const [source , setSource] = useState(jobOpenings.length > 0 ? jobOpenings[0].source : '')
    const [sourceUrl , setSourceUrl] = useState(jobOpenings.length > 0 ? jobOpenings[0].sourceUrl : "")

    const staticSources = ['Teamtailor', 'Jobylon', 'The Hub', 'Manually', 'Import from CSV']
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState(jobOpenings.map(jobOpening => {
        return {
            ...jobOpening,
            key: uuid()
        }
    }))

    const [current, setCurrent] = useState(null)
    const [showModal, setShowModal] = useState(false)

    const AddModal = (props) => {
        const key = current && current.uuid || props.index
        return (
            <AJModalV2
                visible={showModal}
                closable={true}
                centered={true}
                width={750}
                onClose={()=>{
                    setShowModal(false)
                }}>
                <AJCard width={800}
                        height={'infinity'}
                        borderRadius={22}
                        type={'wide'}
                        hideClose={true}>
                    <div style={{padding: '32px 32px 22px'}} key={key}>
                        <div style={{fontSize: 22}}>
                            Input Your Job Opening
                        </div>
                        <EmptyLine height={8}/>
                        <AJInput2
                            label={'Title'}
                            id={'input-job-opening-title'}
                            defaultValue={current && current.title}/>
                        <EmptyLine height={8}/>
                        <AJInput2 label={'Location'} id={'input-job-opening-location'}
                                  defaultValue={current && current.location}/>
                        <EmptyLine height={8}/>

                        <AJUrlInput label={'Link'} id={'input-job-opening-link'}
                                    defaultValue={current && current.url}/>
                        <EmptyLine height={16}/>
                        <Row type={'flex'} align={'end'} justify={'end'}>
                            <Col>
                                <AJButton3
                                    size={'small'}
                                    title={'Save'}
                                    titleStyle={{color: 'white'}}
                                    width={103}
                                    height={38}
                                    onClick={()=>{
                                        const title = document.getElementById('input-job-opening-title').value
                                        const location = document.getElementById('input-job-opening-location').value
                                        const link = document.getElementById('input-job-opening-link').value

                                        if (!current) {
                                            dataSource.push({
                                                title,
                                                location,
                                                url: link,
                                                key: uuid()
                                            })
                                        }
                                        else {
                                            current.title = title
                                            current.location = location
                                            current.url = link
                                        }
                                        setDataSource(dataSource)
                                        setShowModal(false)
                                    }}/>
                            </Col>
                        </Row>
                    </div>
                </AJCard>
            </AJModalV2>
        )
    }


    const foundCheckItem = () => {

        for (const s of staticSources) {
            if (source && (s.toLowerCase() === source.toLowerCase()
                || s.replace(/ /, '').toLowerCase() === source.toLowerCase())) {
                return s
            }
        }

        return 'Manually'
    }

    const [checkedItem, setCheckItem] = useState(foundCheckItem)

    const sourceToUrl = {
        Teamtailor: "https://detectify.teamtailor.com/jobs",
        Jobylon: "https://northvolt.com/career",
        'The Hub': "https://thehub.io/startups/edai-technologies"
    }

    const sourceMapping = {
        'Teamtailor' : 'teamtailor',
        'Jobylon' : 'jobylon',
        'The Hub' : 'thehub'
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (title) => {
                return <div>{title}</div>
            },
            width: '60%',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            width: '45%',
        }]

    const mColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '55%',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            width: '30%',

        },
        {
            title: '',
            dataIndex: 'operation',
            key: 'operation',
            width: '15%',

            render: (text, record) => (
                <>
                    <Icon type="edit" onClick={()=>{
                        // if (!record.uuid) {
                        //     record.uuid = uuid()
                        // }
                        setCurrent(record)
                        setShowModal(true)
                    }}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Icon type="delete" onClick={()=>{
                        setDataSource(dataSource.filter(j => j.key !== record.key))
                    }}/>
                </>
            ),
        },
    ];

    const onChange = e => {
        const value = e.target.value
        // if (value === 'Manually') {
        //     setCheckItem(e.target.value)
        // }
        // else {

        if (value === 'Manually') {

            if (jobOpenings && jobOpenings.length > 0 && jobOpenings[0].source === null) {
                setDataSource(jobOpenings.map(jobOpening => {
                    return {
                        ...jobOpening,
                        key: uuid()
                    }
                }))
            } else {
                setDataSource([])
            }
        }

        setCheckItem(e.target.value)
        setStep(1)
    };

    function syncFromApi(checkedItem) {
        return checkedItem === 'Teamtailor' ||
            checkedItem ==='Jobylon' ||
            checkedItem === 'The Hub'
    }

    const [CSVData, setCSVData] = useState([])
    const [CSVImported, setCSVImported] = useState(false)
    const CSVColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Url',
            dataIndex: 'url',
            key: 'url',
        }
    ];
    const draggerProps = {
        accept: ".csv",
        action: async (file) => {
            file.text().then(text => {
                const records = text.split('\n')
                let result = [];
                for (let record of records) {
                    if (record && record.includes('","')) {
                        let fields = record.split('","')
                        fields = fields.map(field => {
                            field = field.replace(/\r|\n/g, '')
                            field = field.replace(/^"|"$/g, '')
                            return field
                        })
                        result.push(
                            {
                                title: fields[2].replace(/\\s*$/, ''),
                                url: fields[1].replace(/\\s*$/, ''),
                                location: fields[0].replace(/\\s*$/, '')
                            }
                        );
                    } else if(record && record.includes('http')) {
                        let parts = record.split('http')
                        const location = parts[0].replace(/\r|\n/g, '').replaceAll('"', '').trim().replace(/,(?=\s*$)/, '').trim()
                        const remainParts = parts[1].split(',') 
                        const url = 'http' + remainParts[0].replace(/\r|\n/g, '').trim()
                        const title = remainParts[1].replace(/\r|\n/g, '').trim()
                        result.push(
                            {
                                title: title,
                                url: url,
                                location: location
                            }
                        );
                    }
                }
                setCSVData(result)
                setLoading(false)
                setCSVImported(true)
            })
        },
        customRequest: () => {
            // disable upload
        },
        showUploadList: false,
    };

    return (
        <>
            <AJModalV2 visible={props.show}
                       width={800}
                       closable={true}
                       centered={true}
                       onClose={()=> {
                           if (props.hide)
                               props.hide()
                       }}>
                <AJCard width={800}
                        height={'infinity'}
                        borderRadius={22}
                        type={'wide'}
                        hideClose={true}
                >

                    <div style={{padding: "50px 30px 20px"}}>
                        <div >
                            <Radio.Group
                                buttonStyle={'solid'}
                                options={staticSources}
                                onChange={onChange}
                                // style={radioStyle}
                                value={checkedItem}>
                            </Radio.Group>
                            {/*<div hidden={checkedItem !== 'Manually'}>*/}
                            {/*    <EmptyLine height={20}/>*/}
                            {/*    <Input*/}
                            {/*        id={`aj-job-openings-input-source`}*/}
                            {/*        placeholder={'Url to job opening(s)'}*/}
                            {/*        defaultValue={foundCheckItem() === 'Manually' ? sourceUrl : null}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<EmptyLine height={checkedItem !== 'Manually' ? 20 : 10}/>*/}
                            <EmptyLine height={10}/>
                            <div hidden={!syncFromApi(checkedItem)}>
                                <Input
                                    id={`aj-job-openings-input-url`}
                                    key={checkedItem}
                                    defaultValue={foundCheckItem() === checkedItem ? sourceUrl : null}
                                    placeholder={checkedItem==='Manually' ? null : sourceToUrl[checkedItem]}/>
                                <EmptyLine height={20}/>
                                <div style={{textAlign: 'right'}} hidden={step!==1}>
                                    <AJButton3
                                        size={'small'}
                                        heigh={30}
                                        width={120}
                                        title={'Next'}
                                        titleStyle={{color: 'white'}}
                                        loading={loading}
                                        onClick={async ()=>{
                                            let sourceNew = checkedItem
                                            if (checkedItem === 'Manually') {
                                                sourceNew = document.getElementById(`aj-job-openings-input-source`).value
                                            }
                                            else {
                                                sourceNew = sourceMapping[sourceNew]
                                            }
                                            let sourceUrlNew = document.getElementById(`aj-job-openings-input-url`).value
                                            setLoading(true)
                                            const response = await jobExtract(sourceNew, sourceUrlNew)
                                            if (response) {
                                                setDataSource(response.data)
                                                setSource(sourceNew)
                                                setSourceUrl(sourceUrlNew)
                                            }
                                            else {
                                                setDataSource(null)
                                                setSource(sourceNew)
                                                setSourceUrl(sourceUrlNew)
                                            }
                                            setLoading(false)
                                            setStep(2)
                                        }}/>
                                </div>
                            </div>
                            <div hidden={checkedItem !== 'Manually'}>
                                <EmptyLine height={20}/>
                                <Table dataSource={dataSource} columns={mColumns}/>
                                <EmptyLine height={20}/>

                                <Row>
                                    <Col span={12} style={{textAlign: 'left'}}>
                                        <AJButton3
                                            size={'small'}
                                            type={'secondary'}
                                            heigh={30}
                                            width={120}
                                            title={'Add'}
                                            onClick={() => {
                                                setCurrent(null)
                                                setShowModal(true)
                                            }}
                                        />
                                    </Col>
                                    <Col span={12} style={{textAlign: 'right'}}>
                                        <AJButton3
                                            size={'small'}
                                            heigh={30}
                                            width={120}
                                            title={'Save'}
                                            titleStyle={{color: 'white'}}
                                            onClick={() => {
                                                if (props.hide)
                                                    props.hide()

                                                if (saveJobOpenings) {
                                                    dataSource ?
                                                        saveJobOpenings(
                                                            dataSource.map((jobOpening, index) => {
                                                                return {
                                                                    ...jobOpening,
                                                                    order: index,
                                                                    source: null,
                                                                    sourceUrl: null
                                                                }

                                                            })
                                                        ) : saveJobOpenings([])
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div hidden={step !== 2}>
                            <EmptyLine height={20}/>

                            <Table dataSource={dataSource} columns={columns}/>
                            <EmptyLine height={20}/>

                            <Row>
                                <Col span={12} style={{textAlign: 'left'}}>
                                    <AJButton3
                                        size={'small'}
                                        heigh={30}
                                        width={120}
                                        title={'Back'}
                                        onClick={()=>setStep(1)}
                                    />
                                </Col>
                                <Col span={12} style={{textAlign: 'right'}}>
                                    <AJButton3
                                        size={'small'}
                                        heigh={30}
                                        width={120}
                                        title={'Save'}
                                        onClick={() => {
                                            if (props.hide)
                                                props.hide()

                                            if (saveJobOpenings) {
                                                saveJobOpenings(
                                                    dataSource.map((jobOpening, index) => {
                                                        return {
                                                            ...jobOpening,
                                                            order: index,
                                                            source: source,
                                                            sourceUrl: sourceUrl
                                                        }
                                                    })
                                                )
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>

                    </div>
                    <div hidden={checkedItem !== 'Import from CSV'}>
                        <div hidden={CSVImported}  style={{padding:'0 32px 48px 32px'}}>
                            <Dragger {...draggerProps}>
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">Click or drag jobopenings CSV file to this area</p>
                            </Dragger>
                        </div>
                        <div hidden={!CSVImported}  style={{padding:'0 32px 32px 32px'}}>
                            <div style={{display: 'flex', padding: "16px 0"}}>
                                <div style={{paddingRight: 16}}>
                                    <Button type={'primary'} onClick={() => {
                                        if (props.hide)
                                            props.hide()

                                        if (saveJobOpenings) {
                                            saveJobOpenings(
                                                CSVData.map((jobOpening, index) => {
                                                    return {
                                                        ...jobOpening,
                                                        order: index,
                                                        source: 'CSV',
                                                        sourceUrl: ''
                                                    }

                                                })
                                            )
                                        }
                                    }}>
                                        Replace
                                    </Button>
                                </div>
                                <div style={{paddingRight: 16}}>
                                    <Button type={'primary'} onClick={() => {
                                        for (const job of dataSource) {
                                            if (!CSVData.find(j => j.url === job.url)) {
                                                CSVData.push(job);
                                            }
                                        }

                                        if (props.hide)
                                            props.hide()

                                        if (saveJobOpenings) {
                                            saveJobOpenings(
                                                CSVData.map((jobOpening, index) => {
                                                    return {
                                                        ...jobOpening,
                                                        order: index,
                                                        source: 'CSV',
                                                        sourceUrl: ''
                                                    }

                                                })
                                            )
                                        }
                                    }}>
                                        Merge
                                    </Button>
                                </div>
                                <div style={{paddingRight: 16}}>
                                    <Button type={'danger'} onClick={() => {
                                        setCSVData([])
                                        setCSVImported(false)
                                    }}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                            <Table loading={false} columns={CSVColumns} dataSource={CSVData}/>
                        </div>

                    </div>

                </AJCard>

            </AJModalV2>
            <AddModal/>
        </>
    )
}

export function getOffsetTop(el){
    return el.offsetParent
        ? el.offsetTop + getOffsetTop(el.offsetParent)
        : el.offsetTop
}
